<template>
  <div class="container py-5 min-vh-50">
    <div class="cart-container-title">
      <span class="cart-title">{{ $t('views.cart') }}</span>
      <span v-if="checkTime" class="cart-timer">Il carrello scade tra: <b>{{ formattedTime }}</b></span>
    </div>
    <div class="cart-container-tabs">
      <div class="cart-container-tabs-item" :class="isSelectedTab(0)" @click="selectTab(0)" @keydown="selectTab(0)">{{ $t('common.cart') }}</div>
      <div class="divider"></div>
      <div class="cart-container-tabs-item" :class="isSelectedTab(1)" @click="selectTab(1)" @keydown="selectTab(1)">{{ $t('common.editdata') }}</div>
      <div class="divider center"></div>
      <div class="cart-container-tabs-item" :class="isSelectedTab(2)" @click="selectTab(2)" @keydown="selectTab(2)">{{ $t('common.summary') }}</div>
      <div class="divider"></div>
      <div class="cart-container-tabs-item">{{ $t('common.payment') }}</div>
    </div>
    <div class="cart-container-body">
      <KeepAlive>
        <component :is="tabs[selectedTab].component"></component>
      </KeepAlive>
    </div>
    <div class="cart-container-subbody">
      <span>Acquistando i prodotti di Pro Cremona stai supportando</span>
      <img src="@/assets/thisability.png" alt="">
    </div>
  </div>
</template>

<script>
import CartViewCart from '@/components/cart/CartViewCart.vue';
import CartViewEditData from '@/components/cart/CartViewEditData.vue';
import CartViewSummary from '@/components/cart/CartViewSummary.vue';

import { piniaCart } from '@/pinia';
import {
  computed, onMounted, ref, watch,
} from 'vue';

export default {
  components: {
    CartViewCart,
    CartViewEditData,
    CartViewSummary,
  },
  setup() {
    const pinia = piniaCart();
    const time = ref(0);
    const tabs = [
      {
        value: 0,
        component: 'CartViewCart',
        title: 'cart',
      },
      {
        value: 1,
        component: 'CartViewEditData',
        title: 'editdata',
      },
      {
        value: 2,
        component: 'CartViewSummary',
        title: 'summary',
      },
    ];
    let timerObject;

    const selectedTab = computed(() => pinia.getSelectedTab);
    //  const time = computed(() => pinia.getFormattedTimer);
    const checkTime = computed(() => time.value > 0);
    const formattedTime = computed(() => new Date(time.value * 1000).toISOString().substring(14, 19));

    function selectTab(value) {
      if (value < pinia.selectedTab) {
        pinia.selectedTab = value;
      }
    }

    function isSelectedTab(value) {
      return pinia.selectedTab >= value ? 'selected' : '';
    }

    function timer() {
      timerObject = setTimeout(() => {
        time.value -= 1;
        if (time.value > 0) {
          timer();
        } else {
          window.location.reload();
        }
      }, 1000);
    }

    onMounted(() => {
      pinia.selectedTab = 0;
    });

    watch(() => pinia.getTimer, (value) => {
      clearTimeout(timerObject);
      time.value = value;
      timer();
    }, { deep: true });

    return {
      tabs,
      selectedTab,
      selectTab,
      isSelectedTab,
      time,
      checkTime,
      formattedTime,
    };
  },
};
</script>

<style lang="scss" scoped>
  .cart-container-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .cart-title {
      font-family: Poppins;
      font-size: 30px;
      font-weight: bold;
      color: #B82620;
    }
    .cart-timer {
      font-family: Poppins;
      font-size: 15px;
      font-weight: bold;
      color: #6E6E6E;
      b {
        font-size: 18px;
      }
    }
  }
  .cart-container-tabs {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 25px;
    .divider {
      flex-grow: 1;
      border-bottom: 2px solid #B82620;
    }
    .cart-container-tabs-item {
      border: 2px solid #6E6E6E;
      border-radius: 25px;
      width: 250px;
      padding: 5px;
      text-align: center;
      font-family: Poppins;
      font-weight: bold;
      color: #6E6E6E;
    }
    .cart-container-tabs-item.selected {
      color: #FFF;
      background-color: #B82620;
    }
  }
  @media (max-width: 767.98px) {
    .cart-container-tabs {
      justify-content: center;
      .cart-container-tabs-item {
        width: 100%;
        margin-bottom: 15px;
      }
      .divider {
        display: none;
      }
    }
  }
  @media (max-width: 991.98px) {
    .cart-container-tabs {
      .divider.center {
        display: none;
      }
    }
  }
  @media (max-width: 1199.98px) {
    .cart-container-tabs {
      .cart-container-tabs-item {
        margin-bottom: 15px;
      }
      .divider {
        margin-bottom: 15px;
      }
    }
  }
  .cart-container-body {
    display: flex;
    margin-top: 25px;
  }
  .cart-container-subbody {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 35px;
    gap: 15px;
    span {
      font-family: Poppins;
      font-size: 14px;
      font-weight: bold;
      color: #000
    }
    img {
      width: 200px;
      height: auto;
    }
  }
</style>
