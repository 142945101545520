import { createI18n } from 'vue-i18n';

const messages = {
  it: {
    views: {
      experiences: 'Esperienze',
      merch: 'Merch',
      cart: 'Carrello',
      contacts: 'Contatti',
      conditions: 'Condizioni di vendita',
    },
    footer: {
      company: '© 2022 Pro Cremona',
    },
    common: {
      addtocart: 'Aggiungi al carrello',
      gotocheckout: 'Vai al checkout',
      emptysearch: 'La ricerca non ha prodotto risultati',
      cart: 'Carrello',
      editdata: 'Compila i tuoi dati',
      summary: 'Riepilogo',
      payment: 'Pagamento',
      merch: 'Merch',
      experiences: 'Esperienze',
      contacts: 'Contatti',
      contactssubtitle: 'Hai qualche dubbio su qualcosa?<br>Scrivici scegliendo l\'oggetto, ci aiuterà a risponderti più accuratamente',
      ok: 'Ok',
      shipmentdatas: 'Dati per la spedizione',
      change: 'Modifica',
      cancel: 'Annulla',
      confirm: 'Conferma',
      size: 'Taglia',
      material: 'Materiale',
      color: 'Colore',
    },
  },
  en: {
    views: {
      experiences: 'Experiences',
      merch: 'Merch',
    },
  },
};

const i18n = createI18n({
  locale: 'it',
  messages,
});

export default i18n;
